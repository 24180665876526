<template>
    <div class="admin-items">
        <div class="items-content">
            <div class="content-header">
                <div class="header-element element-name">NAME</div>
                <div class="header-element element-stable">STABLE</div>
                <div class="header-element element-price">PRICE</div>
                <div class="header-element element-custom">CUSTOM PRICE</div>
                <div class="header-element element-accepted">ACCEPTED</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="adminItemList.data === null || adminItemList.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminItemList.data.length > 0" class="list-data" key="data">

                        <AdminItemElement v-for="item in adminItemList.data" v-bind:key="item._id" v-bind:item="item" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO ITEMS FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="adminSetPage" v-bind:page="adminItemList.page" v-bind:count="adminItemList.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import AdminItemElement from '@/components/admin/items/AdminItemElement';

    export default {
        name: 'adminItemList',
        components: {
            LoadingAnimation,
            Pagination,
            AdminItemElement
        },
        methods: {
            ...mapActions([
                'adminGetItemListSocket', 
                'adminSetItemListPage', 
                'adminSetFilterSearch'
            ]),
            adminSetPage(page) {
                this.adminSetItemListPage(page);
                this.adminGetItemListSocket({ page: this.adminItemList.page, search: this.adminFilterSearch });
            }
        },
        computed: {
            ...mapGetters([
                'adminItemList', 
                'adminFilterSearch'
            ])
        },
        created() {
            if(this.adminItemList.loading === false) {
                this.adminGetItemListSocket({ page: this.adminItemList.page, search: this.adminFilterSearch });
            }
        },
        beforeRouteLeave(to, from, next) {
            this.adminSetFilterSearch('');
            next();
        }
    }
</script>

<style scoped>
    .admin-items {
        width: 100%;
    }

    .admin-items .items-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-items .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-items .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-items .header-element.element-name {
        width: 35%;
    }

    .admin-items .header-element.element-stable,
    .admin-items .header-element.element-price {
        width: 20%;
    }

    .admin-items .header-element.element-custom {
        width: 15%;
    }

    .admin-items .header-element.element-accepted {
        width: 10%;
    }

    .admin-items .header-element.element-accepted {
        display: flex;
        justify-content: flex-end;
    }

    .admin-items .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .admin-items .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-items .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-items .list-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-items .list-data {
        width: 100%;
    }

    .admin-items .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-items .list-data.fade-enter-active,
    .admin-items .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-items .list-data.fade-enter-from,
    .admin-items .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1200px) {

        .admin-items .items-content {
            padding: 10px;
        }

        .admin-items .content-header {
            display: none;
        }

        .admin-items .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>
