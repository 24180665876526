<template>
    <div class="admin-reports">
        <div class="reports-content">
            <div class="content-header">
                <div class="header-element element-users">NEW USERS</div>
                <div class="header-element element-blackjack">BLACKJACK</div>
                <div class="header-element element-mines">MINES</div>
                <div class="header-element element-towers">TOWERS</div>
                <div class="header-element element-plinko">PLINKO</div>
                <div class="header-element element-upgrader">UPGRADER</div>
                <div class="header-element element-deposited">DEPOSITED</div>
                <div class="header-element element-withdrawn">WITHDRAWN</div>
                <div class="header-element element-date">DATE</div>
            </div>
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="adminReportList.data === null || adminReportList.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminReportList.data.length > 0" class="list-data" key="data">

                        <AdminReportElement v-for="report in adminReportList.data" v-bind:key="report._id" v-bind:report="report" />

                    </div>
                    <div v-else class="list-empty" key="empty">NO REPORTS FOUND.</div>
                </transition>
            </div>
        </div>

        <Pagination v-on:setPage="adminSetPage" v-bind:page="adminReportList.page" v-bind:count="adminReportList.count" countPage="14" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import AdminReportElement from '@/components/admin/reports/AdminReportElement';

    export default {
        name: 'AdminStats',
        components: {
            LoadingAnimation,
            Pagination,
            AdminReportElement
        },
        methods: {
            ...mapActions([
                'adminGetReportListSocket', 
                'adminSetReportListPage'
            ]),
            adminSetPage(page) {
                this.adminSetReportListPage(page);
                this.adminGetReportListSocket({ page: this.adminReportList.page });
            }
        },
        computed: {
            ...mapGetters([
                'adminReportList'
            ])
        },
        created() {
            if(this.adminReportList.loading === false) {
                this.adminGetReportListSocket({ page: this.adminReportList.page });
            }
        }
    }
</script>

<style scoped>
    .admin-reports {
        width: 100%;
    }

    .admin-reports .reports-content {
        width: 100%;
        padding: 15px;
        border-radius: 8px;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-reports .content-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-reports .header-element {
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-reports .header-element.element-users,
    .admin-reports .header-element.element-blackjack,
    .admin-reports .header-element.element-mines,
    .admin-reports .header-element.element-towers,
    .admin-reports .header-element.element-plinko,
    .admin-reports .header-element.element-upgrader,
    .admin-reports .header-element.element-deposited,
    .admin-reports .header-element.element-withdrawn {
        width: 10%;
    }

    .admin-reports .header-element.element-date {
        width: 20%;
        display: flex;
        justify-content: flex-end;
    }

    .admin-reports .content-list {
        width: 100%;
        margin-top: 8px;
    }

    .admin-reports .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-reports .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-reports .list-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-reports .list-data {
        width: 100%;
    }

    .admin-reports .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-reports .list-data.fade-enter-active,
    .admin-reports .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-reports .list-data.fade-enter-from,
    .admin-reports .list-empty.fade-enter-from {
        opacity: 0;
    }

    @media only screen and (max-width: 1200px) {

        .admin-reports .reports-content {
            padding: 10px;
        }

        .admin-reports .content-header {
            display: none;
        }

        .admin-reports .content-list {
            width: 100%;
            margin-top: 0;
        }

    }
</style>