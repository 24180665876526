<template>
    <router-link v-bind:to="adminGetRoute" class="admin-button-create">CREATE</router-link>
</template>

<script>
    export default {
        name: 'AdminButtonCreate',
        computed: {
            adminGetRouteName() {
                return this.$route.name;
            },
            adminGetRoute() {
                let route = '/admin';

                if(this.adminGetRouteName === 'AdminBoxes') { route = '/admin/boxes/create'; }
                else if(this.adminGetRouteName === 'AdminCodes') { route = '/admin/codes/create'; }
                else if(this.adminGetRouteName === 'AdminLeaderboards') { route = '/admin/leaderboards/create'; }
                else if(this.adminGetRouteName === 'AdminGiveaways') { route = '/admin/giveaways/create'; }
                else if(this.adminGetRouteName === 'AdminBots') { route = '/admin/bots/create'; }

                return route;
            }
        }
    }
</script>

<style scoped>
    a.admin-button-create {
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        padding: 0 20px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 600;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
        transition: background 0.3s ease;
    }

    a.admin-button-create:hover {
        background: #00de56;
    }

    @media only screen and (max-width: 950px) {

        a.admin-button-create  {
            width: 100%;
            margin-right: 0;
            margin-bottom: 5px;
        }

    }
</style>