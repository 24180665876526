<template>
    <div class="admin-box-selected-item">
        <div class="element-info">
            <img v-bind:src="item.image" />
            <div class="info-text">
                {{ item.name }}
                <div class="text-amount">
                    <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                    <div class="amount-value">
                        <span>{{ adminFormatValue(item.priceFixed).split('.')[0] }}</span>.{{ adminFormatValue(item.priceFixed).split('.')[1] }}
                    </div>
                </div>
            </div>
        </div>
        <input v-model="item.percentage" type="number" min="0.001" max="100" step="0.001" placeholder="Enter item percentage here..." />
        <button v-on:click="adminRemoveBoxListSelected(item)" class="button-remove">
            <svg data-v-d6637120="" width="11" height="11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
                <path data-v-d6637120="" fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 1.22222L9.77827 0L5.50019 4.27808L1.22222 0.000111474L0 1.22233L4.27797 5.5003L0.000501987 9.77776L1.22272 11L5.50019 6.72252L9.77776 11.0001L11 9.77788L6.72241 5.5003L11.0005 1.22222Z"></path>
            </svg>
        </button>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'AdminBoxSelectedItem',
        props: ['item'],
        methods: {
            ...mapActions([
                'adminRemoveBoxListSelected'
            ]),
            adminFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        }
    }
</script>

<style scoped>

    .admin-box-selected-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;
    }

    .admin-box-selected-item input {        
        width: calc(100% - 50px);
        height: 45px;
        margin-top: 5px;
        margin-right: 5px;
        padding: 0 0 0 10px;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #191e27;
        border: 1px solid #262c3a;
    }

    .admin-box-selected-item input::placeholder {
        color: #596076;
    }

    .admin-box-selected-item .element-info {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 700;
        color: #596076;
        background: #12161d;
    }

    .admin-box-selected-item .element-info img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .admin-box-selected-item .info-text {
        display: flex;
        flex-direction: column;
    }

    .admin-box-selected-item .text-amount  {
        display: flex;
        align-items: center;
        margin-top: 3px;
    }

    .admin-box-selected-item .text-amount img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    .admin-box-selected-item .amount-value {
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .admin-box-selected-item .amount-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    .admin-box-selected-item button.button-remove {
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        border-radius: 5px;
        background: #fd3b31;
    }

    .admin-box-selected-item button.button-remove svg {
        fill: #ffffff;
    }

</style>

