<template>
    <div class="admin-filter-nav" v-bind:class="{ 'nav-open': adminDropdown === true }">
        <button v-on:click="adminToggleDropdown()" class="button-toggle">
            {{ adminGetRoute }}
            <svg width="13" height="9" viewBox="0 0 13 9" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.2142 0.361328L6.5 5.07555L1.78577 0.361328L0 2.1471L6.5 8.6471L13 2.1471L11.2142 0.361328Z" />
            </svg>
        </button>
        <div class="nav-menu">
            <div class="menu-inner">
                <router-link to="/admin" v-on:click.native="adminToggleDropdown()">DASHBOARD</router-link>
                <router-link to="/admin/users" v-on:click.native="adminToggleDropdown()">USERS</router-link>
                <router-link to="/admin/transactions" v-on:click.native="adminToggleDropdown()">TRANSACTIONS</router-link>
                <router-link to="/admin/items" v-on:click.native="adminToggleDropdown()">ITEMS</router-link>
                <router-link to="/admin/boxes" v-on:click.native="adminToggleDropdown()">BOXES</router-link>
                <router-link to="/admin/codes" v-on:click.native="adminToggleDropdown()">CODES</router-link>
                <router-link to="/admin/leaderboards" v-on:click.native="adminToggleDropdown()">LEADERBOARDS</router-link>
                <router-link to="/admin/reports" v-on:click.native="adminToggleDropdown()">REPORTS</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminFilterNav',
        data() {
            return {
                adminDropdown: false
            }
        },
        methods: {
            ...mapActions([
                'adminSetFilterStats', 
                'adminGetUserDataSocket'
            ]),
            adminToggleDropdown() {
                this.adminDropdown = !this.adminDropdown;
            }
        },
        computed: {
            ...mapGetters([
                'adminFilterStats', 
                'adminStatsData', 
                'adminUserData'
            ]),
            adminGetRoute() {
                let route = this.$route.name.replace('Admin', '');

                if(route === 'BoxesCreate') { route = 'Boxes'; }
                else if(route === 'CodesCreate') { route = 'Codes'; }
                else if(route === 'LeaderboardsCreate') { route = 'Leaderboards'; }
                else if(route === 'BotsCreate') { route = 'Bots'; }

                return route.toUpperCase();
            }
        },
        mounted() {
            let self = this;
            document.addEventListener('click', function(event) {
                if(!self.$el.contains(event.target) && self.adminDropdown == true) {
                    self.adminToggleDropdown();
                }
            });
        }
    }
</script>

<style scoped>
    .admin-filter-nav {
        width: 230px;
        position: relative;
        z-index: 10;
    }

    .admin-filter-nav button.button-toggle {
        width: 100%;
        height: 43px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #767c8b;
        background: #212732;
        box-shadow: 0px 2px 0px 0px #191e27;
    }

    .admin-filter-nav button.button-toggle:hover {
        color: #ffffff;
    }

    .admin-filter-nav.nav-open button.button-toggle {
        color: #fd3b31;
        background: #252129;
        box-shadow: 0px 0px 0px 1px #59272b;
    }

    .admin-filter-nav button.button-toggle svg {
        fill: #626c7e;
        transition: all 0.3s ease;
    }

    .admin-filter-nav.nav-open button.button-toggle svg {
        transform: rotate(180deg);
        fill: #fd3b31;
    }

    .admin-filter-nav .nav-menu {
        width: 100%;
        height: 0;
        position: absolute;
        top: 52px;
        left: 0;
        overflow: hidden;
        transition: height 0.2s ease;
    }

    .admin-filter-nav.nav-open .nav-menu {
        height: 384px;
    }

    .admin-filter-nav .menu-inner {
        width: 100%;
        padding: 3px;
        border-radius: 5px;
        background: #212732;
        border: 1px solid #191e27;
    }

    .admin-filter-nav .menu-inner a {
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        padding: 0 14px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-filter-nav .menu-inner a:hover {
        background: #191d26;
    }

    @media only screen and (max-width: 950px) {

        .admin-filter-nav  {
            width: 100%;
        }

    }
</style>