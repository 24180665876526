<template>
    <div class="admin-transaction-element">
        <div class="element-section section-method">
            <div class="section-title">METHOD</div>
            <div class="section-content">
                {{adminGetMethod}}
            </div>
        </div>
        <div class="element-section section-amount">
            <div class="section-title">AMOUNT</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(transaction.amount).split('.')[0]}}</span>.{{generalFormatAmount(transaction.amount).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-state" v-bind:class="'section-' + transaction.state">
            <div class="section-title">STATE</div>
            <div class="section-content">
                {{transaction.state.toUpperCase()}}
            </div>
        </div>
        <div class="element-section section-date">
            <div class="section-title">DATE</div>
            <div class="section-content">
                {{ new Date(transaction.createdAt).toLocaleString('en-US', { hour12: true }) }}
            </div>
        </div>
        <div class="element-section section-transaction">
            <div class="section-title">TRANSACTION</div>
            <div class="section-content">
                <div v-if="transaction.method === 'steam'" class="content-steam">
                    <img v-for="item in transaction.data.items.slice(0, 6)" v-bind:key="item.assetid" v-bind:src="'https://steamcommunity-a.akamaihd.net/economy/image/'+item.image" />
                    <span v-if="transaction.data.items.length > 6">+{{transaction.data.items.length - 6}}</span>
                </div>
                <div v-else class="content-gift">

                </div>
            </div>
        </div>
        <div class="element-section section-actions">
            <div class="section-title">ACTIONS</div>
            <div class="section-content">
                <router-link v-bind:to="'/admin/users/'+transaction.user" class="link-user">USER</router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';

    export default {
        name: 'AdminTransactionElement',
        mixins: [
            mixins
        ],
        props: [
            'transaction'
        ],
        computed: {
            adminGetMethod() {
                let method = this.transaction.method.toUpperCase() + ' ' + this.transaction.type.toUpperCase();

                if(this.transaction.method === 'crypto') { method = this.transaction.data.currency.toUpperCase() + ' ' + this.transaction.type.toUpperCase(); }

                return method;
            }
        }
    }
</script>

<style scoped>
    .admin-transaction-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-transaction-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-transaction-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-transaction-element .element-section.section-method,
    .admin-transaction-element .element-section.section-date,
    .admin-transaction-element .element-section.section-transaction {
        width: 20%;
    }

    .admin-transaction-element .element-section.section-amount,
    .admin-transaction-element .element-section.section-state {
        width: 15%;
    }

    .admin-transaction-element .element-section.section-actions {
        width: 10%;
    }

    .admin-transaction-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-transaction-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-transaction-element .element-section.section-method .section-content,
    .admin-transaction-element .element-section.section-state .section-content,
    .admin-transaction-element .element-section.section-date .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-transaction-element .element-section.section-state.section-sent .section-content,
    .admin-transaction-element .element-section.section-state.section-created .section-content {
        color: #f4d03f;
    }

    .admin-transaction-element .element-section.section-state.section-completed .section-content {
        color: #00c74d;
    }

    .admin-transaction-element .element-section.section-state.section-canceled .section-content,
    .admin-transaction-element .element-section.section-state.section-failed .section-content {
        color: #fd3b31;
    }

    .admin-transaction-element .element-section.section-actions .section-content {
        justify-content: flex-end;
    }

    .admin-transaction-element .element-section.section-amount .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-transaction-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-transaction-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-transaction-element .content-steam {
        display: flex;
        align-items: center;
    }

    .admin-transaction-element .content-steam img {
        width: 30px;
        margin-right: 5px;
    }

    .admin-transaction-element .content-steam img:last-of-type {
        margin-right: 0;
    }

    .admin-transaction-element .content-steam span {
        margin-left: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-transaction-element a.link-user {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-transaction-element a.link-user:hover {
        background: #00de56;
    }

    @media only screen and (max-width: 1200px) {

        .admin-transaction-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-transaction-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-transaction-element .element-section.section-method {
            margin-top: 0;
        }

        .admin-transaction-element .element-section.section-actions {
            align-items: flex-start;
        }

        .admin-transaction-element .section-title {
            display: block;
        }

        .admin-transaction-element .section-content {
            margin-top: 5px;
        }

    }
</style>
