<template>
    <div class="admin-box-items">
        <div class="items-content">
            <div class="content-list">
                <transition name="fade" mode="out-in">
                    <div v-if="adminItemList.data === null || adminItemList.loading === true" class="list-loading" key="loading">
                        <LoadingAnimation />
                    </div>
                    <div v-else-if="adminItemList.data.length > 0" class="list-data" key="data">

                        <AdminBoxItemElement v-for="item in adminItemList.data" v-bind:key="item._id" v-bind:item="item" />

                    </div>
                    <div v-else class="list-empty" key="empty">No items found.</div>
                </transition>
            </div>

            <Pagination v-on:setPage="adminSetPage" v-bind:page="adminItemList.page" v-bind:count="adminItemList.count" />
        </div>
        <div class="items-selected">
            <div class="selected-list">
                <AdminBoxSelectedElement v-for="item in adminBoxList.selected" v-bind:key="item._id" v-bind:item="item" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import Pagination from '@/components/Pagination';
    import AdminBoxItemElement from '@/components/admin/boxes/AdminBoxItemElement';
    import AdminBoxSelectedElement from '@/components/admin/boxes/AdminBoxSelectedElement';

    export default {
        name: 'AdminBoxItems',
        components: {
            LoadingAnimation,
            Pagination,
            AdminBoxItemElement,
            AdminBoxSelectedElement
        },
        methods: {
            ...mapActions([
                'adminSetFilterSearch',
                'adminSetItemListPage',
                'adminGetItemListSocket'
            ]),
            adminSetPage(page) {
                this.adminSetItemListPage(page);
                this.adminGetItemListSocket({ page: this.adminItemList.page, search: this.adminFilterSearch });
            }
        },
        computed: {
            ...mapGetters([
                'adminFilterSearch',
                'adminItemList',
                'adminBoxList'
            ])
        }
    }
</script>

<style scoped>
    .admin-box-items {
        width: 100%;
        display: flex;
        margin-top: 10px;
    }

    .admin-box-items .items-content {
        width: calc(100% - 265px);
        margin-right: 15px;
    }

    .admin-box-items .content-list,
    .admin-box-items .items-selected {
        padding: 10px;
        border-radius: 8px;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        background: linear-gradient(180deg, #1a1e29 0%, #1c202c 100%);
    }

    .admin-box-items .content-list::-webkit-scrollbar-track,
    .admin-box-items .items-selected::-webkit-scrollbar-track {
        background: transparent;
    }

    .admin-box-items .content-list::-webkit-scrollbar,
    .admin-box-items .items-selected::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .admin-box-items .content-list {
        width: 100%;
        height: 400px;
    }

    .admin-box-items .list-loading {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-box-items .list-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .admin-box-items .list-loading.fade-leave-to {
        opacity: 0;
    }

    .admin-box-items .list-data {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .admin-box-items .list-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-box-items .list-data.fade-enter-active,
    .admin-box-items .list-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .admin-box-items .list-data.fade-enter-from,
    .admin-box-items .list-empty.fade-enter-from {
        opacity: 0;
    }

    .admin-box-items .items-selected {
        width: 250px;
        height: 400px;
    }

    @media only screen and (max-width: 850px) {

        .admin-box-items {
            flex-direction: column-reverse;
        }

        .admin-box-items .items-content {
            width: 100%;
            margin-top: 10px;
            margin-right: 0;
        }

        .admin-box-items .items-selected {
            width: 100%;
            height: 200px;
        }

    }
</style>