<template>
    <div class="admin-item-element">
        <div class="element-section section-name">
            <div class="section-title">NAME</div>
            <div class="section-content">
                {{item.name}}
            </div>
        </div>
        <div class="element-section section-stable" v-bind:class="[{ 'section-unstable': item.stable === false }]">
            <div class="section-title">STABLE</div>
            <div class="section-content">
                {{item.stable === true ? 'STABLE' : 'UNSTABLE'}}
            </div>
        </div>
        <div class="element-section section-price">
            <div class="section-title">PRICE</div>
            <div class="section-content">
                <img src="@/assets/img/icons/coins.webp" alt="coins-image" />
                <div class="content-value">
                    <span>{{generalFormatAmount(item.price).split('.')[0]}}</span>.{{generalFormatAmount(item.price).split('.')[1]}}
                </div>
            </div>
        </div>
        <div class="element-section section-custom">
            <div class="section-title">CUSTOM PRICE</div>
            <div class="section-content">
                <input v-model="adminCustomPrice" v-on:input="adminValidateInput" type="text" v-bind:disabled="adminIsCustom === true" />
                <button v-on:click="adminPriceButton()"  class="button-price" v-bind:class="{ 
                    'button-remove': adminIsCustom === true 
                }" v-bind:disabled="socketSendLoading !== null">{{ adminIsCustom === true ? 'REMOVE' : 'UPDATE' }}</button>
            </div>
        </div>
        <div class="element-section section-accepted">
            <div class="section-title">ACCEPTED</div>
            <div class="section-content">
                <button 
                    v-on:click="adminAcceptedToggle()" 
                    class="button-toggle" 
                    v-bind:class="{ 'button-active': adminAccepted === true }"
                    v-bind:disabled="socketSendLoading !== null"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'AdminItemElement',
        mixins: [
            mixins
        ],
        props: [
            'item'
        ],
        data() {
            return {
                adminIsCustom: false,
                adminCustomPrice: '0.00',
                adminAccepted: false
            }
        },
        methods: {
            ...mapActions([
                'adminSendItemPriceSocket', 
                'adminSendItemAcceptedSocket'
            ]),
            adminValidateInput() {
                 this.adminCustomPrice = this.adminCustomPrice.replace(/[^\d.]/g, '');
            },
            adminPriceButton() {
                if(this.adminIsCustom === true) {
                    this.adminSendItemPriceSocket({ name: this.item.name, price: 0 });

                    this.adminIsCustom = false;
                    this.adminCustomPrice = '0.00';
                } else {
                    const price = Math.floor(this.adminCustomPrice * 1000);

                    if(price === null || isNaN(price) === true || price <= 0) {
                        this.notificationShow({type: 'error', message: 'Your entered custom price is invalid.'});
                        return;
                    }

                    this.adminSendItemPriceSocket({ name: this.item.name, price: price });
                }
            },
            adminAcceptedToggle() {
                this.adminSendItemAcceptedSocket({ name: this.item.name, accepted: !this.adminAccepted });
            }
        },
        computed: {
            ...mapGetters([
                'socketSendLoading', 
                'adminItemList'
            ])
        },
        watch: {
            'adminItemList.data': {
                handler() {
                    this.adminAccepted = this.item.accepted;
                    if(this.item.priceCustom !== undefined) {
                        this.adminIsCustom = true;
                        this.adminCustomPrice = Number(this.item.priceCustom / 1000).toFixed(2);
                    }
                },
                deep: true
            }
        },
        created() {
            this.adminAccepted = this.item.accepted;
            if(this.item.priceCustom !== undefined) {
                this.adminIsCustom = true;
                this.adminCustomPrice = Number(this.item.priceCustom / 1000).toFixed(2);
            }
        }
    }
</script>

<style scoped>
    .admin-item-element {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .admin-item-element:nth-child(odd) {
        border-radius: 8px;
        background: #1d212d;
    }

    .admin-item-element .element-section {
        display: flex;
        flex-direction: column;
    }

    .admin-item-element .element-section.section-name {
        width: 35%;
    }

    .admin-item-element .element-section.section-stable,
    .admin-item-element .element-section.section-price {
        width: 20%;
    }

    .admin-item-element .element-section.section-custom {
        width: 15%;
    }

    .admin-item-element .element-section.section-accepted {
        width: 10%;
    }

    .admin-item-element .section-title {
        display: none;
        font-size: 12px;
        font-weight: 700;
        color: #767c8b;
    }

    .admin-item-element .section-content {
        display: flex;
        align-items: center;
    }

    .admin-item-element .element-section.section-name .section-content,
    .admin-item-element .element-section.section-stable .section-content,
    .admin-item-element .element-section.section-custom .section-content,
    .admin-item-element .element-section.section-accepted .section-content {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-item-element .element-section.section-stable .section-content {
        color: #00c74d;
    }

    .admin-item-element .element-section.section-stable.section-unstable .section-content {
        color: #fd3b31;
    }

    .admin-item-element .element-section.section-custom .section-content {
        position: relative;
    }

    .admin-item-element .element-section.section-accepted .section-content {
        justify-content: flex-end;
    }

    .admin-item-element .section-content img {
        width: 21px;
        margin-right: 10px;
    }

    .admin-item-element .content-value {
        font-size: 12px;
        font-weight: 700;
        color: #bbbbbb;
    }

    .admin-item-element .content-value span {
        font-size: 15px;
        font-weight: 700;
        color: #ffffff;
    }

    .admin-item-element .section-content input {
        width: 100%;
        height: 38px;
        padding: 0 69px 0 10px;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 700;
        color: #ffffff;
        background: #12161d;
    }

    .admin-item-element .section-content input:disabled {
        cursor: not-allowed;
    }

    .admin-item-element .section-content button.button-price {
        width: 65px;
        height: 30px;
        position: absolute;
        top: 50%;
        right: 4px;
        transform: translate(0, -50%);
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #ffffff;
        background: #00c74d;
        border-bottom: 2px solid #00732c;
    }

    .admin-item-element .section-content button.button-price.button-remove {
        background: #fd3b31;
        border-bottom: 2px solid #97302b;
    }

    .admin-item-element .section-content button.button-toggle {
        width: 56px;
        height: 30px;
        position: relative;
    }

    .admin-item-element .section-content button.button-toggle:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 34px;
        background: rgba(253, 59, 49, 0.4);
    }

    .admin-item-element .section-content button.button-toggle.button-active:before {
        background: rgba(0, 199, 77, 0.4);
    }

    .admin-item-element .section-content button.button-toggle:after {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        left: 4px;
        top: 4px;
        border-radius: 50%;
        background: #fd3b31;
        transition: transform 0.3s ease;
    }

    .admin-item-element .section-content button.button-toggle.button-active:after {
        transform: translateX(26px);
        background: #00c74d;
    }

    @media only screen and (max-width: 1200px) {

        .admin-item-element {
            height: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .admin-item-element .element-section {
            width: 100%!important;
            margin-top: 10px;
        }

        .admin-item-element .element-section.section-name {
            margin-top: 0;
        }

        .admin-item-element .element-section.section-accepted {
            align-items: flex-start;
        }

        .admin-item-element .section-title {
            display: block;
        }

        .admin-item-element .section-content {
            margin-top: 5px;
        }

    }
</style>
